// SCREENSHOTS GALLERY
/* global $ */

$(document).ready(function () {
  $('.slider').slick({
    arrows: false,
    dots: true,
    infinite: false,
    initialSlide: 1
  })
})
